import { useEffect, useRef, useState } from 'react';

import { dayjs } from '@totopkg/shared-util-date-time';

export const useDetectFreeze = (
  events?: {
    onFrozen?: () => void;
    onResume?: () => void;
    onActive?: () => void;
    onInactive?: () => void;
  },
  maxFreezeTime = 900000 //** default is 15 minutes */
) => {
  const { onFrozen, onResume, onInactive, onActive } = events || {};

  const freezeTimeRef = useRef<dayjs.Dayjs>();
  const freezeTimeoutRef = useRef<ReturnType<typeof setInterval>>();

  const [isActive, setIsActive] = useState<boolean>(true);
  const [isFrozen, setIsFrozen] = useState<boolean>(false);

  useEffect(() => {
    const detectVisibleFrozen = () => {
      clearTimeout(freezeTimeoutRef.current);
      setIsFrozen(false);

      if (document.hidden) {
        freezeTimeRef.current = dayjs();
        setIsActive(false);

        freezeTimeoutRef.current = setTimeout(() => {
          setIsFrozen(true);
        }, maxFreezeTime);
        return;
      }

      setIsActive(true);
      if (dayjs().diff(freezeTimeRef.current, 'millisecond') >= maxFreezeTime) {
        onResume?.();
      }

      freezeTimeRef.current = undefined;
    };

    document.addEventListener('visibilitychange', detectVisibleFrozen);

    return () => document.removeEventListener('visibilitychange', detectVisibleFrozen);
  }, [maxFreezeTime, onActive, onFrozen, onInactive, onResume]);

  useEffect(() => {
    if (isActive) {
      onActive?.();
    }
  }, [isActive, onActive]);

  useEffect(() => {
    if (!isActive) {
      onInactive?.();
    }
  }, [isActive, onInactive]);

  useEffect(() => {
    if (isFrozen) {
      onFrozen?.();
    }
  }, [isFrozen, onFrozen]);

  return {
    isFrozen,
    isActive
  };
};
