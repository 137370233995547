import { mutatorAction } from 'satcheljs';

import { getStore } from '../store/message.store';

export const updateErrorAction = mutatorAction('updateErrorAction', (message: string | undefined, header?: string) => {
  getStore().message = {
    header,
    message,
    type: 'ERROR'
  };
});
