import { useCallback, useEffect } from 'react';

export const useDetectClickOutside = (ref: React.RefObject<HTMLDivElement>, callback: () => void) => {
  const handleClickOutside = useCallback(
    (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    },
    [ref, callback]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, handleClickOutside]);
};
