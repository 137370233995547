import { mutatorAction } from 'satcheljs';

import { getStore } from '../store/message.store';

export const updateSuccessAction = mutatorAction('updateSuccessAction', (message: string | undefined, header?: string) => {
  getStore().message = {
    header,
    message,
    type: 'SUCCESS'
  };
});
