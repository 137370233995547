import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

import { IMessageStore } from './message.type';

const initStore: IMessageStore = {
  message: undefined,
  localMessage: new ObservableMap()
};

export const getStore = createStore('store-message', initStore);
