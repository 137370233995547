import { mutatorAction } from 'satcheljs';

import { getStore } from '../store/loading.store';

/**
 * @param localId
 * - should include context and specific process id
 * - Example:
 *   - context: Service A
 *   - process: Process A1
 * - localId: a-a1;
 */
export const updateLocalLoadingAction = mutatorAction('updateLocalLoadingAction', (localId: string | undefined, loading: boolean) => {
  if (!localId) return;

  getStore().localLoading.set(localId, loading);
});
