import { mutatorAction } from 'satcheljs';

import { getGlobalStore } from '../store/global.store';

export const removeDataAction = mutatorAction('removeDataAction', (key: string | undefined) => {
  if (!key) return;

  getGlobalStore().data.delete(key);
  localStorage.removeItem(key);
});
