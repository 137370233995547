export interface ILazyPicture {
  sources?: React.SourceHTMLAttributes<HTMLSourceElement>[];

  /** use as default fallback if no source match */
  image?: React.ImgHTMLAttributes<HTMLImageElement>;
}

export interface ILazyPictureProps extends ILazyPicture {
  className?: string;
  style?: React.CSSProperties;
  width?: string;
  height?: string;
}
