import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const GlobalRouterNavigationSetter = () => {
  const history = useHistory();

  useEffect(() => {
    window.navigate = path => history.push(path);
  }, [history]);

  return null;
};

declare global {
  interface Window {
    navigate: (path: string) => void;
  }
}
