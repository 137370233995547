import { mutatorAction } from 'satcheljs';

import { SecureLocalStorage } from '@totopkg/shared-util-local-storage';

import { getGlobalStore } from '../store/global.store';

export const setDataAction = mutatorAction(
  'setDataAction',
  (key: string | undefined, data: unknown, saveLocal = false, encryptLocal = true) => {
    if (!key) return;

    getGlobalStore().data.set(key, data);

    if (saveLocal) {
      SecureLocalStorage.setItem(key, data, encryptLocal);
    }
  }
);
