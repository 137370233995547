import { useEffect, useState } from 'react';

export const useNetworkDetect = (events?: { onOffline?: () => void; onOnline?: () => void }) => {
  const { onOffline, onOnline } = events || {};
  const [isOnline, setIsOnline] = useState<boolean>();

  useEffect(() => {
    window.addEventListener('offline', e => {
      onOffline?.();
      setIsOnline(false);
    });

    window.addEventListener('online', e => {
      onOnline?.();
      setIsOnline(true);
    });
  }, [onOffline, onOnline]);

  return {
    isOnline
  };
};
