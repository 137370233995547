import { AppHistory } from '../router/app-history.util';

export function replaceUrlQuery(newQueries: Record<string, string | string[] | undefined>) {
  // Parse the current URL
  const url = new URL(window.location.href);

  Object.keys(newQueries).forEach(key => {
    const value = newQueries[key];
    // Remove the query parameter if the value is undefined
    if (!value) return url.searchParams.delete(key);

    // Set the query parameter
    url.searchParams.set(key, [value].flat().join(','));
  });

  // Update the browser's address bar without reloading the page
  AppHistory.replace(url);
}
