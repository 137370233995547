/**
 * - minLength: 6
 * - maxLength: 20
 * - startWith: alphabet
 * - contain: alphabet, number, '.' and `_`
 */
export const USERNAME_PATTERN = /^[A-Za-z][A-Za-z0-9_.]{5,20}$/;

/**
 * - minLength: 6
 * - maxLength: 20
 * - at least 1 uppercase character and 1 lowercase character and 1 digits
 * - at least 1 special character(#?!@$%^&*-_)
 *
 */
export const PASSWORD_PATTERN = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{6,}$/;

/**
 * - startWith: `+`
 * - countryCode: 1 to 3 digits OR can be `1` and subCode 1 to 3 digits between `()`,
 * ex: 84 or 1(987)
 * - endWith: 9 to 15 digits
 */
export const PHONE_PATTERN = /^[+](([0-9]{1,3})|(1\(([0-9]{1,3})\)))[\s]*([0-9]{9,15})$/;

export const EMAIL_PATTERN = /^\S+@\S+\.\S+$/;
