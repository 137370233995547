/**
 * The above type defines a callback object that can be used to handle success, error, and finally
 * actions.
 * @property success - A callback function that is called when the action being performed is
 * successful. It takes an optional parameter `response` of type `SuccessResponse`, which represents
 * the response data returned by the action.
 * @property error - The `error` property is a callback function that is intended to be called when an
 * error occurs during the action being performed. It takes an optional `error` parameter, which
 * represents the error that occurred. This callback function can be used to handle and display the
 * error to the user, or perform any
 * @property finally - The `finally` property is a callback function that is intended to be called
 * after the action being performed, regardless of whether it was successful or resulted in an error.
 * It can be used to perform any cleanup or finalization tasks.
 */

export type TActionCallback<SuccessResponse = unknown, Error = string> = {
  /**
   * @description
   * This callback function is intended to be called when the action being performed is successful. */
  success?: (response?: SuccessResponse) => void;

  /**
   * @description
   * This callback function is intended to be called when an error occurs during the action being performed. */
  error?: (error?: Error) => void;

  /**
   * @description
   * This callback function is intended to be called after the action being performed,
   * regardless of whether it was successful or resulted in an error.
   * It can be used to perform any cleanup or finalization tasks. */
  finally?: () => void;
};
