import { ILazyPictureProps } from './lazy-picture.type';

export type TIcon = {
  width?: string;
  height?: string;
} & (
  | {
      iconType?: 'svg' | 'ionic' | 'kendo';
      icon?: string;
    }
  | {
      iconType?: 'picture';
      icon?: ILazyPictureProps;
    }
);
