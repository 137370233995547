import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

import { IGlobalStore } from './global.type';

const initStore: IGlobalStore = {
  data: new ObservableMap()
};

export const getGlobalStore = createStore('global-store', initStore);
