import axios from 'axios';
import { useEffect, useState } from 'react';

export const useFileContent = <T = string>(
  fileUrl: string | undefined,
  options?: {
    pending?: boolean;
  }
) => {
  const { pending = false } = options || {};

  const [content, setContent] = useState<T>();

  useEffect(() => {
    if (pending || !fileUrl) return;

    axios
      .get(fileUrl)
      .then(response => setContent(response.data as T))
      .catch(console.error);
  }, [fileUrl, pending]);

  return {
    content
  };
};
