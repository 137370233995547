export function formatTextValues(text: string, values: { [key: string | number]: any }) {
  try {
    const regex = new RegExp(
      Object.keys(values)
        .map(key => `\\{\\{${key}\\}\\}`)
        .join('|'),
      'gi'
    );

    return text.replace(regex, matched => {
      const _valueKey = matched.replace(/{+/, '').replace(/}+/, '');
      return values[_valueKey] || matched;
    });
  } catch (error) {
    return text;
  }
}
