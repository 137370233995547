export function getSmartLink(originUrl: string | undefined) {
  if (!originUrl) return '/';

  const { host, hostname } = window.location;

  const token = localStorage.getItem('token') ?? '';
  const lang = localStorage.getItem('lang') ?? 'vi';
  const domain = hostname.split('.').slice(-2).join('.');

  return originUrl
    .replace(new RegExp('{origin}', 'gi'), origin)
    .replace(new RegExp('{hostname}', 'gi'), hostname)
    .replace(new RegExp('{domain}', 'gi'), domain)
    .replace(new RegExp('{host}', 'gi'), host)
    .replace(new RegExp('{token}', 'gi'), token)
    .replace(new RegExp('{lang}', 'gi'), lang);
}
