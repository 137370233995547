import { ObservableMap } from 'mobx';

export type TMessageType = 'DEFAULT' | 'INFO' | 'WARNING' | 'SUCCESS' | 'ERROR';

export interface IMessage {
  header?: string;
  message?: string;
  type?: TMessageType;
}

export type TLocalId = string;

export interface IMessageStore {
  message: IMessage | undefined;
  localMessage: ObservableMap<TLocalId, IMessage>;
}
