import _copy from 'copy-to-clipboard';

import { translate } from '@totopkg/shared-util-language';

import { MessageStore } from '../store-message';

export function copy(text: string | number | undefined, options?: Parameters<typeof _copy>[1]) {
  if (!text) return;
  try {
    const copied = _copy(text.toString(), options);

    if (copied) {
      MessageStore.updateSuccessAction(text.toString(), translate('label.copied'));
    }
  } catch (error) {
    MessageStore.updateErrorAction(error?.toString());
  }
}
