import { observable } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getStore } from '../store/loading.store';

export const updateLoadingAction = mutatorAction('updateLoadingAction', (loading: boolean) => {
  const store = getStore();

  store.loading = observable.box(loading);
});
