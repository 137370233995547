import { createBrowserHistory } from 'history';

export const AppHistory = createBrowserHistory();

export const goBack = (defaultHref = '/') => {
  const canGoBack = AppHistory.length > 2;

  if (!canGoBack && !defaultHref) return;

  if (canGoBack) {
    AppHistory.goBack();
  } else if (defaultHref) {
    AppHistory.replace(defaultHref);
  }
};

// bind goBack to win action
(Window as any).goBack = goBack;
