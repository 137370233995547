import { mutatorAction } from 'satcheljs';

import { getStore } from '../store/message.store';

export const updateLocalSuccessAction = mutatorAction(
  'updateLocalSuccessAction',
  (localId: string, message: string | undefined, header?: string) => {
    getStore().localMessage.set(localId, {
      header,
      message,
      type: 'SUCCESS'
    });
  }
);
