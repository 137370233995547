import { replaceUrlQuery } from './replace-url-query.util';

export function removeUrlQuery(keys: string | string[]) {
  const queries = Array.isArray(keys) ? keys : [keys];

  const newQueries = queries.reduce(
    (accumulator, key) => {
      accumulator[key] = undefined;
      return accumulator;
    },
    {} as Record<string, string | string[] | undefined>
  );

  replaceUrlQuery(newQueries);
}
