import { observable, ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

import { ILoadingStore } from './loading.type';

const initStore: ILoadingStore = {
  loading: observable.box(false),
  localLoading: new ObservableMap()
};

export const getStore = createStore('store-loading', initStore);
